<template>
  <div class="ConfidentialityPolitique" >
    <div class="container">
      <div class="row text-center mt-4">
        <div class="col-12">
          <h2 class="help-title">Politique de confidentialité</h2>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            A. Introduction
          </h3>
          <p>
            La confidentialité des visiteurs de notre site web est très importante
            à nos yeux, et nous nous engageons à la protéger.
            Cette politique détaille ce que nous faisons de vos informations personnelles.
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            B. Collecte d’informations personnelles
          </h3>
          <p>
            Les types d’informations personnelles suivants peuvent collectés et utilisés :
            <ul>
              <li>
                Des informations à propos de votre ordinateur, y compris votre adresse IP,
                le type et la version de votre navigateur, et votre système d’exploitation ;
              </li>
              <li>
                Les informations saisies lors de la création de votre profil : prénom, nom, email, date d'entrée au sein de votre entreprise
                type de contrat de travail actuel, date de naissance, numéro de téléphone et catégorie professionnelle ;
              </li>
              <li>
                Votre mot de passe n'est pas stocké en clair sur nos seveurs, seul le hash de votre mot de passe est stocké. Nous utilisons
                l’algorithme PBKDF2 avec une fonction de hachage SHA256, un mécanisme d’étirement de mot de passe recommandé par le NIST ;
              </li>
              <li>
                Les informations d'état civil que vous nous transmettez et qui permettent de réaliser votre bilan retraite individuel :
                numéro de sécurité social, nombre d'enfants, si vous avez effectué votre service militaire ainsi que les dates de début et de fin,
                si vous avez effectué une activité professionnelle à l'étranger ainsi que le(s) pay(s) ou les périodes, si vous souhaitez faire une demande de rachat de trimestre ainsi que le nombre de
                trimestres et la période concernée ;
              </li>
              <li>
                Les documents que vous nous transmettez et qui permettent de réaliser votre bilan retraite individuel :
                document d'identité (carte d'identité ou passeport) ;
              </li>
              <li>
                Votre bilan retraite individuel qui sera réalisé par votre conseiller retraite ;
              </li>
              <li>
                Les informations contenues dans toutes les communications que vous nous envoyez
                par e-mail ou sur notre site web, y compris leurs contenus et leurs métadonnées ;
              </li>
              <li>
                Toute autre information personnelle que vous nous communiquez ;
              </li>
            </ul>
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            D. Utilisation de vos informations personnelles
          </h3>
          <p>
            Les informations personnelles fournies par le biais de
            notre site web seront utilisées dans les objectifs décrits dans cette
            politique ou dans les pages du site pertinentes.
            Vos informations sont seulement utilisées pour :
            <ul>
              <li>Administrer notre site web ; </li>
              <li>Personnaliser votre interface ; </li>
              <li>Constituer votre bilan retraite individuel par un conseiller Audiens ; </li>
              <li>Vous envoyer des notifiactions par e-mail sur l'avancée de votre dossier de constitution de votre bilan retraite individuel ; </li>
              <li> Maintenir la sécurité de notre site web et empêcher la fraude ; </li>
              <li> Vérifier le respect des conditions générales qui régissent l’utilisation de notre site web </li>
            </ul>
            Aucune utilisation de vos données personnelles à des fins statistiques ou marketing ne sera réalisée. <br>
            Aucune de vos données ne sera transmise à des tierces parties.
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            E. Divulgation de vos informations personnelles
          </h3>
          <p>
            Vos informations personnelles ne seront accessibles que par votre conseiller Audiens en charge de la réalisation de votre bilen retraite
            individuel. Celui-ci pourra télécharger vos documents et sauvegarder vos données pour mener à bien sa mission.

            Nous pouvons divulguer vos informations personnelles :
            <ul>
              <li>
                Dans la mesure où nous sommes tenus de le faire par la loi ;
              </li>
              <li>
                Dans le cadre de toute procédure judiciaire en cours ou à venir ;
              </li>
              <li>
                Pour établir, exercer ou défendre nos droits légaux (y compris fournir des informations à d’autres à des
                fins de prévention des fraudes) ;
              </li>
              <li>
               A toute personne que nous estimons raisonnablement faire partie intégrante d’un tribunal ou autre autorité compétente pour la divulgation de ces informations personnelles si, selon notre opinion, un tel tribunal ou une telle autorité serait susceptible de demander la divulgation de ces informations personnelles.
               Sauf disposition contraire de la présente politique, nous ne transmettrons pas vos informations personnelles à des tierces parties.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            F. Stcokage et transfert de données
          </h3>
          <p>
            Les informations que nous collectons sont stockées et utilisées en France.
            Vos informations ne seront en aucun cas transférées ou stockées dans un autre pays que la France.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            G. Conservation de vos informations personnelles
          </h3>
          <p>
            Cette Section G détaille nos politiques de conservation des données et nos procédures,
            conçues pour nous aider à nous conformer à nos obligations légales concernant la conservation et la
            suppression d’informations personnelles.
            Les informations personnelles que nous traitons à quelque fin que ce soit ne sont pas
            conservées plus longtemps que nécessaire à cette fin ou à ces fins. Une fois votre dossier terminé, toutes
            vos donénes personnelles seront supprimées du serveur de stockage hébergeant le site et votre profil sera supprimé.

            Dans tous les cas, vos données seront supprimées au plus tard le 01/07/2022 à 12h00.

            Nonobstant les autres dispositions de cette Section G, nous conserverons des
            documents (y compris des documents électroniques) contenant des données personnelles:
            <ul>
              <li>Dans la mesure où nous sommes tenus de le faire par la loi ;</li>
              <li>Si nous pensons que les documents peuvent être pertinents pour toute procédure judiciaire en cours ou potentielle ; </li>
              <li>Pour établir, exercer ou défendre nos droits légaux
                (y compris fournir des informations à d’autres à des fins de prévention des fraudes). </li>
            </ul>
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            H. Sécurité de vos informations personnelles
          </h3>
          <p>
            Nous prendrons des précautions techniques et organisationnelles raisonnables
            pour empêcher la perte, l’abus ou l’altération de vos informations personnelle.<br>
            Nous stockerons toutes les informations personnelles que vous nous
            fournissez sur des serveurs sécurisés (protégés par mot de passe et pare-feu). <br>
            Tous les transferts d'informations et documents effectués par le biais de notre site web seront protégés par des technologies de cryptage.<br>
            Vous reconnaissez que la transmission d’informations par internet est
            intrinsèquement non sécurisée, et que nous ne pouvons pas garantir la sécurité de vos données envoyées par internet.<br>
            Vous êtes responsable de la confidentialité du mot de passe que vous utilisez pour
            accéder à notre site web ; nous ne vous demanderons pas votre mot de passe (sauf quand vous vous identifiez sur notre site web).<br>
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            I. Amendements
          </h3>
          <p>
            Nous pouvons parfois mettre cette politique à jour en publiant une
            nouvelle version sur notre site web.
            Vous devez vérifier cette page régulièrement pour vous
            assurer de prendre connaissance de tout changement effectué à cette politique.
            Nous pouvons vous informer des changements effectués à cette politique
            par courrier électronique.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            J. Vos droits
          </h3>
          <p>
            Vous pouvez nous demander de vous fournir toute information
            personnelle que nous détenons sur vous.
            Le transfert de telles informations sera soumis aux conditions suivantes:
            <ul>
              <li>
                La présentation de preuves suffisantes de votre identité (à ces fins, nous acceptons
                en général une photocopie de votre passeport certifiée par notaire
                plus une copie originale d’une facture de service public indiquant votre adresse actuelle).
              </li>
              <li>Nous pouvons retenir les informations personnelles que vous demandez dans la mesure autorisée par la loi.</li>
            </ul>

            En pratique, vous exprimerez expressément et à l’avance
            votre accord pour que nous utilisions vos informations personnelles pour la réalisation
            de votre bilan retraite individuel.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            K. Sites web tiers
          </h3>
          <p>
            Notre site web contient des liens hypertextes menant vers des sites web
            tiers et des informations les concernant. Nous n’avons aucun
            contrôle sur ces sites, et ne sommes pas responsables de leurs
            politiques de confidentialité ni de leurs pratiques.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            L. Mise à jour des informations
          </h3>
          <p>
            Merci de nous faire savoir si les informations personnelles que
            nous détenons à votre sujet doivent être corrigées ou mises à jour.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            M. Cookies
          </h3>
          <p>
            Notre site web utilise des cookies.
            Un cookie est un fichier contenant un identifiant (une chaîne de lettres et de chiffres)
            envoyé par un serveur web vers un navigateur web et stocké par le navigateur.
            L’identifiant est alors renvoyé au serveur à chaque fois que le navigateur demande une page au serveur.
            Les cookies peuvent être « persistants » ou « de session » : un cookie persistant est stocké par le
            navigateur et reste valide jusqu’à sa date d’expiration, à moins d’être supprimé par l’utilisateur avant cette date d’expiration ;
            quant à un cookie de session, il expire à la fin de la session utilisateur, lors de la fermeture du navigateur.
            Les cookies ne contiennent en général aucune information permettant
            d’identifier personnellement un utilisateur, mais les informations personnelles q
            ue nous stockons à votre sujet peuvent être liées aux informations stockées dans les cookies et obtenues
            par les cookies. Nous utilisons des cookies de session et des cookies persistants sur notre site web.

            Les noms des cookies que nous utilisons sur notre site web et les objectifs dans lesquels nous les utilisons sont décrits ci-dessous:
            <ul>
              <li>
                - Votre ID de session vous permettant de rester connecté sur notre site. Par mesure de sécurité, ces cookies sont
                inutilisables au bout de 7 jours et vous devrez vous reconnecter
              </li>
              <li>
                - Votre profil d'utilisateur pour adapter et personnaliser notre site web à vos besoins
              </li>
            </ul>
            Sans l'utilisation de ces données stockées, l'utilisation du site web n'est pas possible.

            Vous pouvez supprimer les cookies déjà stockés sur votre ordinateur. Exemple:
            avec Internet Explorer (version 10), vous devez supprimer le fichier
            cookies manuellement (vous pourrez trouver des instructions pour le faire ici http://support.microsoft.com/kb/278835);
            avec Firefox (version 24), vous pouvez supprimer les cookies en cliquant sur «Outils»,
            «Options», et «Confidentialité», puis en sélectionnant «Utiliser des paramètres personnalisés pour l’historique» et en cliquant sur «Montrer les cookies», puis sur «Supprimer tous les cookies»; et
            avec Chrome (version 29), vous pouvez supprimer tous les cookies en accédant au menu
            «Personnaliser et contrôler» puis en cliquant sur «Paramètres», « Montrer les paramètres avancés » et «Supprimer les données de navigation» puis «Supprimer les cookies et les données des modules d’autres sites» avant de cliquer sur «Supprimer les données de navigation».
            Supprimer les cookies aura un impact négatif sur l’utilisation de nombreux sites web.
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfidentialityPolitique',
}
</script>
